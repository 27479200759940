import React from 'react';

import { ScPage } from '../components/page/styled';
import { ScTop } from '../components/Top/styled';
import { MainLayout } from '../layouts/main-layout';
import { TheCookiesWeSet } from '../sections/CookiePolicy/TheCookiesWeSet';
import { WhatIsCookie } from '../sections/CookiePolicy/WhatIsCookie';
import {SEO} from "../components/Seo/Seo";

const CookiePolicy = () => {
	return (
		<MainLayout>
			<ScPage>
				<ScTop disableHeight>
					<WhatIsCookie />
					<TheCookiesWeSet />
				</ScTop>
			</ScPage>
		</MainLayout>
	);
};

export default CookiePolicy;

export const Head = () => (
	<SEO
		title="Cookie Policy | Gogeta’s Use of Cookies and Data Protection"
		description="Learn how Gogeta uses cookies to enhance your browsing experience. Understand our cookie policy and data protection measures for a secure and transparent experience."
	/>
)
